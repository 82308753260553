import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import { EuiSpacer, EuiButton, EuiHideFor, EuiShowFor } from "@elastic/eui";
import TableTechSpecs from "components/Outdoor_Cameras/IN-9408_WQHD/Technical_Specifications/Table";
import FooterCards from "components/Outdoor_Cameras/IN-9408_WQHD/Technical_Specifications/FooterCards";
import PrimaryBox from "components/Outdoor_Cameras/IN-9408_WQHD/Technical_Specifications/PrimaryBox";
import NavButtons from "components/Outdoor_Cameras/IN-9408_WQHD/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
export const _frontmatter = {
  "title": "IN-9408 WQHD Manual Technical Specifications",
  "path": "/Outdoor_Cameras/IN-9408_WQHD/Technical_Specifications/",
  "dateChanged": "2022-10-08",
  "author": "Mike Polinowski",
  "excerpt": "The IN-9408 WQHD is the first INSTAR Outdoor IP Surveillance camera, utilizing our new Sony 1440p / WQHD STARVIS sensor.",
  "image": "./P_SearchThumb_IN-9008HD_Technical_Specifications.png",
  "social": "/images/Search/P_SearchThumb_IN-9408WQHD_Technical_Specifications.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9008HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEOHelmet title="IN-9408 WQHD Manual Technical Specifications" dateChanged="2017-12-12" author="Mike Polinowski" tag="INSTAR IP Camera" description="The IN-9408 WQHD is the first INSTAR Outdoor IP Surveillance camera, utilizing our new Sony 1440p / WQHD STARVIS sensor." image="/images/Search/P_SearchThumb_IN-9408HD_Technical_Specifications.png" twitter="/images/Search/P_SearchThumb_IN-9408HD_Technical_Specifications.webp" location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE="/de/Aussenkameras/IN-9408_WQHD/Technische_Spezifikationen/" locationFR="/fr/Outdoor_Cameras/IN-9408_WQHD/Technical_Specifications/" crumbLabel="Tech Specs" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-9408-wqhd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-9408-wqhd-manual",
        "aria-label": "in 9408 wqhd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9408 WQHD Manual`}</h2>
    <h3 {...{
      "id": "technical-specifications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#technical-specifications",
        "aria-label": "technical specifications permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Technical Specifications`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <TableTechSpecs mdxType="TableTechSpecs" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "footnotes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#footnotes",
        "aria-label": "footnotes permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Footnotes:`}</h3>
    <ul>
      <li parentName="ul">{`[1]`}{` `}<OutboundLink href="https://www.sony-semicon.co.jp/products/common/pdf/IMX335LLN_LQN_Flyer.pdf" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink"><EuiButton fill mdxType="EuiButton">{`Sony IMX335 CMOS Sensor`}</EuiButton></OutboundLink></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <FooterCards mdxType="FooterCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ul>
      <li parentName="ul">{`[3]`}{` `}<Link to="/Products/SMA_RP-SMA_Antenna_Connector/" mdxType="Link">{`SMA vs. RP-SMA Antenna Connection`}</Link></li>
      <li parentName="ul">{`[5]`}{` `}<OutboundLink href="https://www.instar.com/support/help-tools" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`SD Card Calculator`}</OutboundLink></li>
      <li parentName="ul">{`[6]`}{` `}<Link to="https://www.sony-semicon.co.jp/e/products/IS/security/technology.html" mdxType="Link">{`STARVIS`}</Link>{` is a trademark of Sony Corporation. The STARVIS is back-illuminated pixel technology used in CMOS image sensors for surveillance camera applications. It features a sensitivity of `}<em parentName="li">{`2000 mV`}</em>{` or more per `}<em parentName="li">{`1 μm²`}</em>{` when illuminated
with a `}<em parentName="li">{`706 cd/m²`}</em>{` light source, `}<em parentName="li">{`F5.6`}</em>{` in `}<em parentName="li">{`1s`}</em>{` accumulation equivalent.`}</li>
      <li parentName="ul">{`[7]`}{` General Recommendations for the Panasonic PIR Sensor:`}</li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      